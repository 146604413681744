import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './Wish.css'
import { useSelector, useDispatch } from 'react-redux';
import { selectWishes, setWishes, setWishesSync } from './wishSlice';
import { useCookies } from 'react-cookie';

function Wish(props) {
  const [cookies] = useCookies();
  const wishList = useSelector(selectWishes);
  const dispatch = useDispatch();

  async function deleteWish(index) {
    let newWishList = Array.from(wishList);
    newWishList.splice(index, 1);
    dispatch(setWishesSync(newWishList));
    await dispatch(setWishes({jwt: cookies.jwt, userId: cookies.uid, wishes: newWishList}));
  }

  return (
    <div className="Wish container">
      <div className="wish-top">
        <h3>{props.wish.name}</h3>
        {props.wish.link ? 
          <a className="link" href={props.wish.link} target="_blank" rel="noopener noreferrer">Link</a> : 
          <div className="link"><i>No Link Provided</i></div>}
      </div>
      <div>{props.wish.details ? props.wish.details : <i>No details provided</i>}</div>
      <div className="delete icon-button" onClick={() => {deleteWish(props.index)}}>
        <FontAwesomeIcon icon={faTrash}/>
      </div>
    </div>
  );
}

export { Wish };
