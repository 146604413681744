import axios from 'axios';
import * as ROUTES from '../constants/routes'

const STORE_SERVICE_URL = `${ROUTES.HOST}${ROUTES.STORES}`

export default {
  getStores,
  createTransaction
};

export const StoreService = (jwt) => axios.create({
  baseURL: STORE_SERVICE_URL,
  headers: {authorization: `Bearer ${jwt}`}
});

function getStores(jwt, groupId, userId) {
  console.log(jwt, groupId, userId)
  return StoreService(jwt).get(`/${groupId}/${userId}`);
}

function createTransaction(jwt, id, transaction) {
  return StoreService(jwt).patch(`/${id}/create-transaction`, transaction);
}