import { 
  createSlice, 
  createAsyncThunk
} from '@reduxjs/toolkit'

import storeAPI from '../../services/store-service'
import userAPI from '../../services/user-service'


const initialState = {
  error: null,
  status: 'idle',
  balance: 0,
  stores: [],
  store: {}
}

export const getBalance = createAsyncThunk(
  'store/getBalance', 
  async ({ jwt, userId }, { rejectWithValue }) => {
    try {
      const response = await userAPI.get(jwt, userId);
      return response.data.points;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStores = createAsyncThunk(
  'store/getStores', 
  async ({ jwt, groupId, userId }, { rejectWithValue }) => {
    console.log("ok.......")
    try {
      const response = await storeAPI.getStores(jwt, groupId, userId);
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err.response.data)
      return rejectWithValue(err.response.data);
    }
  }
);

export const createTransaction = createAsyncThunk(
  'store/createTransaction',
  async ({ jwt, id, uid, item, timestamp }, { rejectWithValue }) => {
    try {
      const response = await storeAPI.createTransaction(jwt, id, { uid, item, timestamp });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.store = action.payload;
    }
  },
  extraReducers: {
    [getBalance.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getBalance.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.balance = action.payload
    },
    [getStores.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getStores.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.stores = action.payload
    },
    [createTransaction.pending]: (state, action) => {
      state.status = 'loading'
    },
    [createTransaction.fulfilled]: (state, action) => {
      state.status = 'succeeded'
    },
    [createTransaction.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  }
});

export const { setStore } = storeSlice.actions;

export const selectError = state => state.store.error;
export const selectStatus = state => state.store.status;
export const selectBalance = state => state.store.balance
export const selectStores = state => state.store.stores;
export const selectStore = state => state.store.store;

export default storeSlice.reducer;
