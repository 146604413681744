import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({component: Component, accessible, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => accessible === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}

export { PrivateRoute };