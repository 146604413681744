import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import './StoreCard.css'
import { useDispatch } from 'react-redux';
import { setStore } from './storeSlice';

function StoreCard(props) {
  const dispatch = useDispatch();

  function handleClick(store) {
    dispatch(setStore(store))
  }

  return (
    <div className="StoreCard container" onClick={() => {handleClick(props.store)}}>
      <div className="graphic">
        <FontAwesomeIcon icon={faLightbulb} size={"5x"} color="pink"/>
      </div>
      <h3 className="name">{props.store.name}</h3>
    </div>
  )
}

export { StoreCard };