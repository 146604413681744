import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import { Top } from './views/top';
import { PrivateRoute } from './common';
import { Login, Register } from './features/auth';
import * as ROUTES from './constants/routes';
import { useCookies } from 'react-cookie';

function App() {
  const [cookies] = useCookies();
  const isLoggedin = cookies.uid !== undefined && cookies.uid !== null;

  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path={ROUTES.LOGIN} component={Login}/>
          <Route path={ROUTES.REGISTER} component={Register}/>
          <PrivateRoute accessible={isLoggedin} path={ROUTES.LANDING} component={Top}/>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
