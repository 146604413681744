import React from 'react'
import cogoToast from 'cogo-toast';
import { TextField, TextareaAutosize } from '@material-ui/core'
import { AwesomeButton } from 'react-awesome-button';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { selectStatus, addWish } from './wishSlice';
import './AddWish.css'

function AddWish() {
  const [cookies] = useCookies();
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);

  function handleChange(e) {
    const { name, value } = e.target;
    const newValues = {...values}
    newValues[name] = value;
    setValues(newValues);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { name } = values;
    if (!name) {
      cogoToast.error("Name is required");
      return;
    }
    if (status === "loading" || status === "loadingNew") return;
    await dispatch(addWish({jwt: cookies.jwt, userId: cookies.uid, wish: values}));
    setValues({ name: "", link: "", details: "" });
  }

  return (
    <div className="AddWish container">
      <form onSubmit={handleSubmit}>
        <TextField name="name" label="name" variant="outlined" size="small"
          onChange={handleChange} value={values.name}/>
        <TextField name="link" label="link" variant="outlined" size="small"
          onChange={handleChange} value={values.link}/>
        <TextareaAutosize name="details" className="details" label="details" variant="outlined" size="small" rowsMin={5}
          onChange={handleChange} value={values.details}/>
        <AwesomeButton className="add-button" size="large" type="primary">Add Item</AwesomeButton>
      </form>
    </div>
  )
}

export { AddWish };