import React from 'react'
import { useCookies } from 'react-cookie';
import './Post.css'
import { capitalizeFirstLetter } from '../../../common';

function Post(props) {
  const [cookies] = useCookies()

  return (
    <div className={cookies.username === props.post.name ? "Post mine" : "Post"}>
      <div className="name">{capitalizeFirstLetter(props.post.name)}</div>
      <div className="content">{props.post.content}</div>
    </div>
  );
}

export { Post };