import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import './Logout.css';

function Logout() {
  const history = useHistory()
  const [, ,removeCookie] = useCookies();

  function logout() {
    removeCookie('uid');
    removeCookie('jwt');
    removeCookie('group');
    removeCookie('username');
    history.push('/login');
    cogoToast.success("Successfully logged out");
  }

  return (
    <div className="Logout nav-button" onClick={logout}>
      <FontAwesomeIcon className="nav-icon" icon={faSignOutAlt} />
    </div>
  );
}

export { Logout };
