import { 
  createSlice, 
  createAsyncThunk
} from '@reduxjs/toolkit'

import groupAPI from '../../services/group-service'


const initialState = {
  error: null,
  status: 'idle',
  group: {
    posts: []
  }
}

export const getGroup = createAsyncThunk(
  'group/get', 
  async (groupId, { rejectWithValue }) => {
    try {
      const response = await groupAPI.get(groupId)
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addPost = createAsyncThunk(
  'group/addPost',
  async (args, { rejectWithValue }) => {
    const { groupId, name, content } = args;
    try {
      const response = await groupAPI.addPost(groupId, name, content);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  extraReducers: {
    [getGroup.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getGroup.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.group = action.payload
    },
    [getGroup.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [addPost.pending]: (state, action) => {
      state.status = 'loading'
    },
    [addPost.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.group = action.payload
    },
    [addPost.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  }
});

// export const { } = groupSlice.actions;

export const selectError = state => state.group.error;
export const selectStatus = state => state.group.status;
export const selectGroup = state => state.group.group;
export const selectAnnouncement = state => state.group.group.announcement;
export const selectPosts = state => state.group.group.posts;

export default groupSlice.reducer;
