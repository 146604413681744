import axios from 'axios';
import * as ROUTES from '../constants/routes'

const GROUP_SERVICE_URL = `${ROUTES.HOST}${ROUTES.GROUP}`

export default {
  get,
  addPost
};

export const GroupService = axios.create({
  baseURL: GROUP_SERVICE_URL,
});

function get(groupId) {
  return GroupService.get(`/${groupId}`);
}

function addPost(groupId, name, content) {
  return GroupService.patch(`/${groupId}/add-post`, { name, content });
}