import React from 'react'
import './Memory.css'

function Memory(props) {
  return (
    <div className="Memory">
      <div className="memory bs">
        <img src={props.imgSrc} alt={props.alt}/>
      </div>
    </div>
  );
}

export { Memory };