import React from 'react';
import Fade from 'react-reveal/Fade';
import { WishList } from './WishList'
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import './Wishes.css'
import { capitalizeFirstLetter } from '../../common';

function Wishes() {
  const [cookies] = useCookies();
  const [currentId, setCurrentId] = useState(cookies.uid);
  const [memberList, setMemberList] = useState([]);

  function generateWishMember(member) {
    return <div className={member.selected ?  "wish-member selected" : "wish-member"} onClick={() => wishMemberClicked(member.uid)} key={member.uid}>
        {member.uid === cookies.uid ? member.name : capitalizeFirstLetter(member.name)+"'s"} Wish List</div>
  }

  async function wishMemberClicked(memberId) {
    const newList = memberList.map(member => {
      member.selected = memberId === member.uid;
      return member
    });
    setMemberList(newList);
    setCurrentId(memberId);
  }

  useEffect(() => {
    console.log("ay");
    const groupMembers = cookies.group.members;
    let myIndex = 0;
    for (let i=0; i<groupMembers.length; i++) {
      const groupMember = groupMembers[i];
      if (groupMember.uid === cookies.uid) {
        myIndex = i;
      }
    }
    const myMember = groupMembers.splice(myIndex, 1)[0];
    myMember.selected = true;
    myMember.name = "My";
    groupMembers.unshift(myMember);
    setMemberList(groupMembers);
  }, [cookies.group.members, cookies.uid])

  return (
    <div className="Wishes content-top">
      <Fade left>
        <h1>Wishes</h1>
        <div className="wish-members">{memberList.map(generateWishMember)}</div>
      </Fade>
      <WishList userId={currentId}/>
    </div>
  );
}

export { Wishes };
