import React from 'react';
import Flicking from "@egjs/react-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import photoIds from './photoids';
import { Memory } from './Memory';
import './Memories.css'

function Memories() {
  const plugins = [new AutoPlay(2000, "NEXT")];

  function generateMemory(photoId) {
    return <Memory key={photoId} imgSrc={`https://i.imgur.com/${photoId}.jpg`} alt='img'/>
  }

  return (
    <Flicking
      className="Memories"
      gap={40}
      overflow={true}
      zIndex={2}
      anchor={"100%"}
      hanger={"50%"}
      circular={true}
      duration={500}
      plugins={plugins}>
        {photoIds.map(generateMemory)}
    </Flicking>
  );
}

export { Memories };