import React from 'react';
import ReactDOM from 'react-dom';
import { Wish } from './Wish';
import { AddWish } from './AddWish';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { selectWishes, getWishes, selectStatus, setWishes, setWishesSync } from './wishSlice';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Component } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import Bounce from 'react-reveal/Bounce';
import './WishList.css'

const portal = document.createElement('div');
portal.classList.add('my-super-cool-portal');

if (!document.body) {
  throw new Error('body not ready for portal creation!');
}

document.body.appendChild(portal);

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle
});


class PortalAwareItem extends Component {
  render() {
    const provided = this.props.provided;
    const snapshot = this.props.snapshot;
    const wish = this.props.wish;
    const index = this.props.index;

    const usePortal = snapshot.isDragging;

    const child = (
      <div className="wish-wrapper"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(
          snapshot.isDragging,
          provided.draggableProps.style
        )}><Wish wish={wish} index={index}/></div>
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
  }
}


function WishList(props) {
  const [cookies] = useCookies();
  const wishList = useSelector(selectWishes);
  const status = useSelector(selectStatus)
  const dispatch = useDispatch();

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function generateWish(wish, index) {
    return <Draggable 
    className="wish-drag" 
    key={wish.name} 
    draggableId={wish.name} 
    index={index}
    isDragDisabled={props.userId !== cookies.uid}>
      {(draggableProvided, draggableSnapshot) => (
        <PortalAwareItem
          wish={wish}
          index={index}
          provided={draggableProvided}
          snapshot={draggableSnapshot}/>
      )}
    </Draggable>
  }

  async function onDragEnd(result) {
    if (!result.source || !result.destination) return;
    const newWishList = reorder(
      wishList,
      result.source.index,
      result.destination.index
    );
    dispatch(setWishesSync(newWishList));
    await dispatch(setWishes({ jwt: cookies.jwt, userId: props.userId, wishes: newWishList }))
  }

  useEffect(() => {
    console.log("ay");
    function getWishList(userId) {
      if (status === "loadingNew" || status === "loading") return;
      dispatch(getWishes({ jwt: cookies.jwt, userId }))
    }
    getWishList(props.userId)
  }, [props.userId])

  return (
    <div className="WishList">
      {
        status === "loadingNew" ?
        <div className="loading-wrapper"><BeatLoader
          size={20}
          color={"#EB6F99"}
          loading={true}/></div> :
        <Bounce left>
          <div className="wishlist-widget">
            <div className="wishlist-wrapper">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" isDropDisabled={props.userId !== cookies.uid}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                        {wishList.map(generateWish)}
                        {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            {props.userId === cookies.uid ? <div className="new-wish-wrapper"><AddWish/></div> : ""}
          </div>
        </Bounce>
      }
    </div>
  );
}

export { WishList };
