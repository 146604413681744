import React from 'react'
import './Announcement.css'

function Announcement(props) {
  return(
    <div className="Announcement container">
      <h2>Announcement</h2>
      <div className="container-sub-element">{props.announcement}</div>
      <h2 className="container-element">Upcoming Events</h2>
      <div className="container-sub-element">F C F C</div>
    </div>
  )
}

export { Announcement };