import React from 'react';
import { Nav } from '../nav';
import { Route, Switch } from 'react-router-dom';
import { Home } from '../../features/home';
import { Stores } from '../../features/stores';
import { Prizes } from '../../features/event';
import { Wishes } from '../../features/wishes';
import * as ROUTES from '../../constants/routes';
import './Top.css';

function Top() {
  return (
    <div className="Top">
      <Nav/>
      <div className="content">
      <Switch>
        <Route path={ROUTES.STORES} component={Stores}/>
        <Route path={ROUTES.PRIZES} component={Prizes}/>
        <Route path={ROUTES.WISHES} component={Wishes}/>
        <Route path={ROUTES.LANDING} component={Home}/>
      </Switch>
      </div>
    </div>
  );
}

export { Top };
