import React, { useEffect } from 'react'
import { Post } from './Post'
import { TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { selectPosts, selectStatus, addPost } from '../groupSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import { unwrapResult } from '@reduxjs/toolkit'
import cogoToast from 'cogo-toast'
import './Chat.css'

function Chat() {
  const [newPost, setNewPost] = useState("");
  const [cookies, setCookie] = useCookies()
  const posts = useSelector(selectPosts)
  const status = useSelector(selectStatus)
  const dispatch = useDispatch()
  
  function generatePost(post, index) {
    return <Post key={index} post={post}/>
  }

  async function handleClick() {
    if (!newPost || status === "loading") return;
    const resultAction = await dispatch(addPost({
      groupId: cookies.group.id, 
      name: cookies.username, 
      content: newPost}));
    try {
      unwrapResult(resultAction)
      setCookie('group', resultAction.payload, { path: '/' });
      setNewPost("");
    } catch (err) {
      cogoToast.error("Failed to write post");
    }
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") return handleClick()
  }

  function handleChange(e) {
    const message = e.target.value;
    setNewPost(message);
  }

  useEffect(() => {
    console.log("ay");
    var postsContainer = document.getElementsByClassName("posts-container")[0];
    postsContainer.scrollTop = postsContainer.scrollHeight;
  })

  return (
    <div className="Chat container">
      <div className="posts-container">
        <div className="posts">
          {posts.map(generatePost)}
        </div>
      </div>
      <div className="bottom">
        <TextField className="text-field" variant="outlined" onChange={handleChange} onKeyPress={handleKeyPress} value={newPost}/>
        <div className="submit-button icon-button" onClick={handleClick}>
          <FontAwesomeIcon className="submit-icon" icon={faPaperPlane}/>
        </div>
      </div>
    </div>
  );
}

export { Chat };