import { 
  createSlice, 
  createAsyncThunk
} from '@reduxjs/toolkit'

import authAPI from '../../services/auth-service'


const initialState = {
  error: null,
  status: 'idle',
  uid: null,
}

export const login = createAsyncThunk(
  'auth/login', 
  async (loginBody, { rejectWithValue }) => {
    try {
      const response = await authAPI.login(loginBody)
      console.log("shiet", response)
      return response.data;
    } catch (err) {
      console.log("shiii", err)
      return rejectWithValue(err.response.data);
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (registerBody, { rejectWithValue }) => {
    try {
      const response = await authAPI.register(registerBody)
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.uid = null
    }
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.status = 'loading'
    },
    [login.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.uid = action.payload.id
    },
    [login.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },    
    [register.pending]: (state, action) => {
      state.status = 'loading'
    },
    [register.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.uid = action.payload.id
    },
    [register.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  }
});

export const { logout } = authSlice.actions;

export const selectError = state => state.auth.error;
export const selectStatus = state => state.auth.status;
export const selectUid = state => state.auth.uid;

export default authSlice.reducer;
