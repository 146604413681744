import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectStore, selectStatus, createTransaction, getBalance } from './storeSlice'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import './Store.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import { unwrapResult } from '@reduxjs/toolkit';
import cogoToast from 'cogo-toast';

function Store() {
  const [cookies] = useCookies()
  const store = useSelector(selectStore)
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);

  async function handleClick(storeItem) {
    if (status === "loading") return;
    const now = new Date();
    const timestamp = Math.round(now.getTime()/1000);
    cogoToast.loading("Purchasing...")
    const resultAction = await dispatch(createTransaction({
      jwt: cookies.jwt, 
      id: store.id,
      uid: cookies.uid,
      item: storeItem.name,
      timestamp
    }))
    try {
      unwrapResult(resultAction);
      dispatch(getBalance({
        jwt: cookies.jwt, 
        userId: cookies.uid
      }))
      cogoToast.success("Transaction complete");
    } catch (err) {
      cogoToast.error(resultAction.payload);
    } 
  }

  function generateAccordionItem(storeItem) {
    return <AccordionItem key={storeItem.name}>
      <AccordionItemHeading className="small-container">
        <AccordionItemButton>
          <div className="item-wrapper">
            <div className="item-name">{storeItem.name}</div>
            <div className="item-cost">cost: {storeItem.cost}</div>
            <div className="purchase-button" onClick={() => {handleClick(storeItem)}}>
              <FontAwesomeIcon icon={faMoneyBillWave}/>
            </div>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="item-details">
        {storeItem.details ? <div>{storeItem.details}</div> : <i>No details provided</i>}
      </AccordionItemPanel>
    </AccordionItem>
  }

  return (
    <div className="Store">
      <h2>{store.name ? store.name : ""}</h2>
      <div className="details">{store.details ? store.details : ""}</div>
      <Accordion className="items">
        {store.items ? store.items.map(generateAccordionItem) : ""}
      </Accordion>
    </div>
  )
}

export { Store }