import React from 'react';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import { Memories } from '../memories';
import { Chat } from '../post';
import { Announcement } from '../announcement';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalizeFirstLetter } from '../../../common';
import { faInstagram, faYoutube, faGithub } from '@fortawesome/free-brands-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { selectStatus, selectAnnouncement, getGroup } from '../groupSlice';
import cogoToast from 'cogo-toast';
import './Home.css'

function Home() {
  const [cookies, setCookie] = useCookies();
  const dispatch = useDispatch();
  const groupStatus = useSelector(selectStatus);
  const announcement = useSelector(selectAnnouncement);

  function handleScroll() {
    const memories = document.getElementsByClassName("Memories");
    const announcement = document.getElementsByClassName("Announcement");
    if (memories && memories[0]) {
      const currentScroll = window.pageYOffset;
      memories[0].style.opacity = 1 - currentScroll / 250;
    } 
    if (announcement && announcement[0]) {
      const currentScroll = window.pageYOffset;
      announcement[0].style.opacity = 1 - currentScroll / 250;
    }
  };

  async function getGroupDetails() {
    if (groupStatus === 'idle') {
      const resultAction = await dispatch(getGroup(cookies.group.id))
      try {
        unwrapResult(resultAction);
        setCookie('group', resultAction.payload, { path: '/' });
      } catch (err) {
        cogoToast.error("Could not retrieve group details");
      }
    }
  }

  useEffect(() => {
    // scroll fade behavior
    console.log("ay");
    window.addEventListener('scroll', handleScroll, { passive: true });
    getGroupDetails();
  })

  return (
    <div className="Home">
      <div className="header-text">
        <Fade left>
          <h1 className="header-welcome">Welcome,</h1>
          <h1 className="header-name">{capitalizeFirstLetter(cookies.username)}</h1>
        </Fade>
      </div>
      <div className="page1 page">
        <Bounce right>
          <div className="announcement">
            <Announcement announcement={announcement}/>
          </div>
        </Bounce>
        <Memories/>
      </div>
      <div className="page2 page">
        <Bounce left>
          <div className="introduction">
            <a className="instagram-icon" href="https://instagram.com/bon.makes"><FontAwesomeIcon icon={faInstagram}/></a>
            <a className="youtube-icon" href="https://youtube.com/tsm"><FontAwesomeIcon icon={faYoutube}/></a>
            <a className="github-icon" href="https://github.com/FrankXuuu"><FontAwesomeIcon icon={faGithub}/></a>
          </div>
        </Bounce>
        <div className="bounce-container">
          <Bounce right>
            <Chat/>
          </Bounce>
        </div>
      </div>
    </div>
  );
}

export { Home };
