import { 
  createSlice, 
  createAsyncThunk
} from '@reduxjs/toolkit'

import userAPI from '../../services/user-service'


const initialState = {
  error: null,
  status: 'idle',
  wishes: []
}

export const getWishes = createAsyncThunk(
  'wish/get', 
  async ({ jwt, userId }, { rejectWithValue }) => {
    try {
      const response = await userAPI.getWishes(jwt, userId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addWish = createAsyncThunk(
  'wish/add',
  async (args, { rejectWithValue }) => {
    const { jwt, userId, wish } = args;
    try {
      const response = await userAPI.addWish(jwt, userId, wish);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const setWishes = createAsyncThunk(
  'wish/set',
  async (args, { rejectWithValue }) => {
    const { jwt, userId, wishes } = args;
    try {
      const response = await userAPI.setWishes(jwt, userId, wishes);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const wishSlice = createSlice({
  name: 'wish',
  initialState,
  reducers: {
    setWishesSync: (state, action) => {
      state.wishes = action.payload;
    }
  },
  extraReducers: {
    [getWishes.pending]: (state, action) => {
      state.status = 'loadingNew'
    },
    [getWishes.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.wishes = action.payload
    },
    [getWishes.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [addWish.pending]: (state, action) => {
      state.status = 'loading'
    },
    [addWish.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.wishes = action.payload
    },
    [addWish.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    },
    [setWishes.pending]: (state, action) => {
      state.status = 'loading'
    },
    [setWishes.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.wishes = action.payload
    },
    [setWishes.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload
    }
  }
});

export const { setWishesSync } = wishSlice.actions;

export const selectError = state => state.wish.error;
export const selectStatus = state => state.wish.status;
export const selectWishes = state => state.wish.wishes;

export default wishSlice.reducer;
