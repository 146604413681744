import React from 'react';
import Fade from 'react-reveal/Fade';
import { StoreList } from './StoreList'
import { Store } from './Store'
import './Stores.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { selectBalance, getBalance } from './storeSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

function Stores() {
  const [cookies] = useCookies()
  const balance = useSelector(selectBalance);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBalance({ jwt: cookies.jwt, userId: cookies.uid }))
  }, [cookies.jwt, cookies.uid])

  return (
    <div className="Stores content-top">
      <Fade left>
        <div className="stores-top">
          <h1>Stores</h1>
          <div className="balance-wrapper">
            <FontAwesomeIcon className="balance-icon" icon={faCoins} color="yellow"/>
            <div className="balance-number">{balance}</div>
          </div>
        </div>
      </Fade>
      <StoreList/>
      <Fade left>
        <Store/>
      </Fade>
    </div>
  );
}

export { Stores };
