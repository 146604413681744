export const LANDING = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const STORES = '/stores';
export const WISHES = '/wishes';

export const HOST = 'https://foon.club'
export const AUTH = '/auth';
export const GROUP = '/groups';
export const USER = '/users';

// special
export const PRIZES = '/prizes';