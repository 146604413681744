import axios from 'axios';
import * as ROUTES from '../constants/routes'

const USER_SERVICE_URL = `${ROUTES.HOST}${ROUTES.USER}`

export default {
  get,
  getWishes,
  addWish,
  setWishes
};

export const UserService = (jwt) => axios.create({
  baseURL: USER_SERVICE_URL,
  headers: {authorization: `Bearer ${jwt}`}
});

function get(jwt, userId) {
  return UserService(jwt).get(`/${userId}`);
}

function getWishes(jwt, userId) {
  return UserService(jwt).get(`/${userId}/wishes`);
}

function addWish(jwt, userId, wish) {
  return UserService(jwt).patch(`/${userId}/add-wish`, wish);
}

function setWishes(jwt, userId, wishes) {
  return UserService(jwt).patch(`/${userId}/set-wishes`, { wishes });
}
