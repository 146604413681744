import './Auth.css';
import * as ROUTES from '../../../constants/routes';
import cogoToast from 'cogo-toast';
import AuthForm from './AuthForm';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { register, selectStatus } from '../authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useCookies } from 'react-cookie';

function Register() {
  const dispatch = useDispatch();
  const history = useHistory();
  const status = useSelector(selectStatus);
  const [, setCookie] = useCookies();

  const handleSubmit = async (username, secret, password) => {
    if (status === "loading") return;

    const resultAction = await dispatch(register({username, secret, password}));
    try {
      unwrapResult(resultAction);
      setCookie('uid', resultAction.payload.id, { path: '/' });
      setCookie('jwt', resultAction.payload.jwt, { path: '/' });
      setCookie('group', resultAction.payload.group, { path: '/' });
      setCookie('username', resultAction.payload.username, { path: '/' });
      cogoToast.success("Successfully registered");
      history.push("/");
    } catch (err) {
      cogoToast.error(resultAction.payload);
    } 
  };

  return (
    <div className="auth-top">
      <div className="auth-container container">
      <h1>Register</h1>
        <div className="text-button">
          <Link to={ROUTES.LOGIN}>Already have an account? Login</Link>
        </div>
        <AuthForm handleSubmit={handleSubmit}/>
      </div>
    </div>
  );
}

export { Register };
