import React, { useEffect, useRef, useState } from 'react';
import { AwesomeButton } from 'react-awesome-button';
import { Wheel } from 'react-custom-roulette'
import ReactPlayer from 'react-player'
import Fade from 'react-reveal/Fade';
import './Prizes.css';

function Prizes() {
  const wheel = useRef(null);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0)
  const [playing, setPlaying] = useState(false)
  const playerHeight = "480px"
  const playerWidth = "270px"
  const data = [
    { option: 'Small Prize' },
    { option: 'Medium Prize' },
    { option: 'Small Prize' },
    { option: 'Spin Again' },
    { option: 'Small Prize' },
    { option: 'LAAARGE PRIZE' },
    { option: 'Small Prize' },
    { option: 'Medium Prize' },
    { option: 'Small Prize' },
    { option: 'Fish choice' },
    { option: 'Small Prize' },
    { option: 'NOTHING!' }
  ]

  const onSpinStart = () => {
    setMustSpin(true)
    setPlaying(true)
  }

  const onSpinStop = () => {
    setPlaying(false)
  }

  const onReset = () => {
    setMustSpin(false)
    wheel.current.childNodes[0].childNodes[0].classList.remove("started-spinning")
    const number = Math.random(data.length)*10
    setPrizeNumber(number);
  }

  useEffect(() => {
    const number = Math.random(data.length)*10
    setPrizeNumber(number);
  }, [setPrizeNumber])

  return (
    <div className="Prizes content-top">
      <div className="side left">
        <ReactPlayer playing={playing} muted={true} width={playerWidth} height={playerHeight} loop={true}
          url='https://www.youtube.com/watch?v=D6Rgqbqgg04&feature=youtu.be' />
      </div>
      <div className="side right">
        <ReactPlayer playing={playing} muted={true} width={playerWidth} height={playerHeight} loop={true}
          url='https://www.youtube.com/watch?v=D6Rgqbqgg04&feature=youtu.be' />
      </div>
      <Fade left>
        <h1>Wheel of Prizes</h1>
      </Fade>
      <div ref={wheel}>
        <Wheel
          onStopSpinning={onSpinStop}
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          backgroundColors={['white', 'red']}
          textColors={['red', 'white']}
          innerBorderWidth={10}
          fontSize={16}
        />
      </div>
      <div>
        <AwesomeButton type="primary" size="large" onPress={onSpinStart}>SPIN</AwesomeButton>
        <AwesomeButton type="secondary" size="large" onPress={onReset}>RESET</AwesomeButton>
      </div>
    </div>
  );
}

export { Prizes };
