import React from 'react';
import { AwesomeButton } from 'react-awesome-button';
import { TextField } from '@material-ui/core'
import 'react-awesome-button/dist/themes/theme-blue.css';

class AuthForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      secret: '',
      password: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });

    const { username, secret, password } = this.state;
    if (username && secret && password)
      this.props.handleSubmit(username, secret, password);
  }

  render() {
    const { username, secret, password, submitted } = this.state;
    const usernameRequired = submitted && !username;
    const secretRequired = submitted && !secret;
    const passwordRequired = submitted && !password;
    return (
      <form name="form" onSubmit={this.handleSubmit}>
        <TextField name="username" label="Username" variant="outlined" size="small" onChange={this.handleChange}
          error={usernameRequired} helperText={usernameRequired ? "Username is required" : ""}/>
        <TextField name="secret" label="Group" variant="outlined" size="small" onChange={this.handleChange}
          error={submitted && !secret} helperText={secretRequired ? "Group is required" : ""}/>
        <TextField name="password" label="Password" variant="outlined" type="password" size="small" onChange={this.handleChange}
          error={submitted && !password} helperText={passwordRequired ? "Password is required" : ""}/>
        <AwesomeButton className="submit-button" size="large" type="primary">Submit</AwesomeButton>
      </form>
    );
  }
}

export default AuthForm;
