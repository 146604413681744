import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIgloo, faStore, faPrayingHands, faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import { Logout } from '../../features/auth';
import './Nav.css';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

function Nav() {
  const history = useHistory();

  const openHome = () => { history.push("/"); }
  const openStore = () => { history.push("/stores"); }
  const openWishes = () => { history.push("/wishes"); }
  const openPrizes = () => { history.push("/prizes"); }

  return (
    <div className="Nav">
      <div className="nav-button icon-button nav-margin" onClick={openHome}>
        <FontAwesomeIcon className="nav-icon" icon={faIgloo} />
      </div>
      <div className="nav-button icon-button nav-margin" onClick={openStore}>
        <FontAwesomeIcon className="nav-icon" icon={faStore} />
      </div>
      <div className="nav-button icon-button nav-margin" onClick={openWishes}>
        <FontAwesomeIcon className="nav-icon" icon={faPrayingHands} />
      </div>      
      <div className="nav-button icon-button nav-margin" onClick={openPrizes}>
        <FontAwesomeIcon className="nav-icon fc" icon={faBoxOpen} />
      </div>
      <Logout />
    </div>
  );
}

export { Nav };
