import React, { useEffect } from 'react';
import Bounce from 'react-reveal/Bounce';
import { StoreCard } from './StoreCard'
import { selectStores, getStores, setStore } from './storeSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import './StoreList.css'

function StoreList() {
  const [cookies] = useCookies();
  const stores = useSelector(selectStores)
  const dispatch = useDispatch();

  function generateStoreCard(store) {
    return <StoreCard key={store.id} store={store}/>
  }

  useEffect(() => {
    async function setStores() {
      const resultAction = await dispatch(getStores({
        jwt: cookies.jwt,
        groupId: cookies.group.id,
        userId: cookies.uid
      }))
      if (resultAction.payload) {
        dispatch(setStore(resultAction.payload[0]));
      }
    }
    setStores()
  }, [cookies.jwt, cookies.group.id, cookies.uid, dispatch])

  return (
    <Bounce left>
      <div className="StoreList">
        {stores.map(generateStoreCard)}
      </div>
    </Bounce>
  )
}

export { StoreList };