import axios from 'axios';
import * as ROUTES from '../constants/routes'

const AUTH_SERVICE_URL = `${ROUTES.HOST}${ROUTES.AUTH}`

export default {
  login,
  register
};

export const AuthService = axios.create({
  baseURL: process.env.AUTH_SERVICE_URL || AUTH_SERVICE_URL,
});

function login(loginBody) {
  return AuthService.post(`/login`, loginBody);
}

function register(registerBody) {
  return AuthService.post(`/register`, registerBody);
}
