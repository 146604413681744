import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import groupReducer from '../features/home/groupSlice'
import storeReducer from '../features/stores/storeSlice'
import wishReducer from '../features/wishes/wishSlice'

export default configureStore({
  reducer: {
    auth: authReducer,
    group: groupReducer,
    store: storeReducer,
    wish: wishReducer
  }
})
